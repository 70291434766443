import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SolutionLayout } from "../components/solutions-layout"
import { TalkToUs } from "../components/talk-to-us"

export default function LiveStreaming() {
  return (
    <Layout tcoreinsight={false}>
      <SEO title="Live Streaming Company" />
      <SolutionLayout
        data={{
          slug: "tstream",
          hero: {
            image: require("./../assets/Image 34.png"),
            titleSecond: "Tstream",
            subtitle: "Stream live video on all platforms",
            content:
              "Distribute high- quality video to your website, apps, Over- the - Top platform, and social channels with Touchcore’s Platform. We ensure your live and archived video reaches your audience in the highest quality, no matter where they watch.",
          },
          section: {
            title: "Stream your event live to your audience",
            subtitle: (
              <>
                Stream live to Youtube, Facebook, Twitter, Instagram and all
                video platforms. Broadcast to any audience, no matter the scale.
                <br />
                We make it easy to stream your town halls, executive
                communications, panels, church service, trainings and everything
                in between.
                <br />
                Touchcore provides livestreaming service through it's startup -
                Tstream.
              </>
            ),
          },
          more_information: <ReachAudience />,
        }}
      />
    </Layout>
  )
}

function ReachAudience() {
  return (
    <>
      <div className="bg-plightpink my-10">
        <div className="container py-10 px-5 md:py-16 md:px-16 text-center">
          <h6 className="text-3xl lg:text-4xl leading-tight mb-4 font-black">
            Reach your target audience Live on any platform
          </h6>
          <p className="text-lg mt-1 mb-5">
            Stream to your website and to Facebook Live, YouTube, Periscope, and
            more
          </p>
          <div className="grid gap-5 grid-cols-3 md:grid-cols-5 lg:flex items-center justify-center space-x-8">
            {[
              require("./../assets/Image 4.png"),
              require("../assets/Image 6.png"),
              require("../assets/Image 7.png"),
              require("../assets/Image 8.png"),
              require("../assets/Image 2.png"),
            ].map(im => (
              <div className="">
                <img className="w-full object-contain" src={im} alt="" />
              </div>
            ))}
          </div>
          <LiveStreamFeatures />
        </div>
      </div>
      <TalkToUs slug={"tstream"} />
    </>
  )
}

export const LiveStreamFeatures = () => {
  const data = [
    {
      image: require("./../assets/Live Links.svg"),
      title: "An End-to-End Solution",
      content:
        "From hardware and software to services & analytics, Tstream has all your streaming needs covered.",
    },
    {
      image: require("./../assets/Verified Badge.svg"),
      title: "Brand Control",
      content: "Easily add your logo to your stream.",
    },
    {
      image: require("./../assets/Streaming Platform.svg"),
      title: "Stream External Events",
      content:
        "Use Tstream’s platform to stream product launches or conferences to social channels.",
    },
    {
      image: require("./../assets/Paid Live Stream.svg"),
      title: "Secure Streaming",
      content:
        "Your company streams are secure. Password protect your streams, embed them within your company’s website.",
    },
  ]
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-16 md:gap-10 my-20">
      {data.map((v, i) => (
        <div
          data-aos="fade-up"
          data-aos-delay={200 * (i + 1)}
          className="text-center md:text-left"
        >
          <img
            className="mx-auto md:ml-0 md:mr-auto w-24 h-24 mb-5"
            src={v.image}
            alt=""
          />
          <h5 className="font-bold text-base">{v.title}</h5>
          <p className="text-sm font-light mt-3">{v.content}</p>
        </div>
      ))}
    </div>
  )
}
